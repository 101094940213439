var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"no-shadow"},[_c('v-toolbar',{staticClass:"mb-4 v-toolbar-header",attrs:{"color":"primary"}},[_c('v-toolbar-title',{attrs:{"aling":"center"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-card-plus")]),_c('div',{staticClass:"d-inline-block dark"},[_vm._v(" Gestion de Almacenamiento ")])],1),_c('v-spacer'),[_c('v-menu',{attrs:{"top":"","close-on-click":_vm.closeOnClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnNuevo"},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"iconsAdd"},[_vm._v("mdi-plus")]),_vm._v(" Nuevo ")],1)]}}])},[_c('v-list',_vm._l((_vm.listaOpcions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{on:{"click":function($event){return _vm.crearNuevo(item)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)]],2),_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-breadcrumbs',{staticClass:"pt-1 cursor",attrs:{"divider":"/"}},_vm._l((_vm.listaPosicionDirectorios),function(item){return _c('v-breadcrumbs-item',{key:item.id,nativeOn:{"click":function($event){return _vm.goDirectory(item.id)}}},[(item.text !== 'Mi Unidad')?[_c('v-icon',[_vm._v("mdi-chevron-right")])]:_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],2)}),1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.detail == 'Directorio')?_c('v-icon',{on:{"click":function($event){return _vm.changePartent(item)}}},[_vm._v(_vm._s(item.icon))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.detail == 'Directorio')?_c('span',{on:{"click":function($event){return _vm.changePartent(item)}}},[_vm._v(_vm._s(item.name))]):_c('span',{on:{"click":function($event){return _vm.donwloadFile(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.estado),"dark":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.detail != 'Directorio')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.donwloadFile(item)}}},[_vm._v(" mdi-arrow-collapse-down ")]):_vm._e(),_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" No hay items ")]},proxy:true}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Confirme desea eliminar permanentemente "+_vm._s(_vm.tipoEliminar)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Volver")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Eliminar")]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"col-12"},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.addArchivo),callback:function ($$v) {_vm.addArchivo=$$v},expression:"addArchivo"}},[_c('div',{staticClass:"container"},[_c('h3',[_vm._v(_vm._s(_vm.selecciono))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-0"},[(
                _vm.selecciono == 'Crear Carpeta' ||
                  _vm.selecciono == 'Editar Carpeta'
              )?_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","required":""},model:{value:(_vm.nombreCarpetaArchivo),callback:function ($$v) {_vm.nombreCarpetaArchivo=$$v},expression:"nombreCarpetaArchivo"}}):_vm._e(),(
                _vm.selecciono == 'Crear Archivo' ||
                  _vm.selecciono == 'Editar Archivo'
              )?_c('v-file-input',{attrs:{"label":"Ingrese nuevo archivo","outlined":""},on:{"change":_vm.obtenerFile},model:{value:(_vm.chosenFile),callback:function ($$v) {_vm.chosenFile=$$v},expression:"chosenFile"}}):_vm._e()],1),_c('div',{staticClass:"col-12 pb-0 text-right",on:{"click":_vm.crear}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.selecciono)+" ")])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }