<template>
  <div>
    <v-card class="no-shadow">
      <v-toolbar color="primary" dark class="mb-4 v-toolbar-header">
        <v-toolbar-title aling="center"
          ><v-icon>mdi-card-text-outline</v-icon> Gestión de Programas de
          aseo</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <template>
          <v-btn class="btn-principal" @click="mostrarDialogo">
            <v-icon>mdi-plus</v-icon>
            Crear Programa
          </v-btn>
        </template>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Estás seguro que deseas eliminar este programa de
              aseo?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Volver</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Eliminar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="crateEditVer" max-width="500px">
          <createPrograma
            :titulo="tituloDialogo"
            :disabledTicket="disabledTicket"
            :dataTicket="dataTicket"
            v-on:recibeTickect="recibeTickect"
          />
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
        class="mb-4"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="tickets"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip :color="getColor(item.estado)" dark>
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="viewItem(item)">
            mdi-eye
          </v-icon>
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          No hay items
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import UserProfile from "../../data";
import createPrograma from "../programas-aseo/create-programa.vue";

export default {
  components: {
    createPrograma,
  },
  data: () => ({
    dataTicket: {},
    disabledTicket: false,
    user: UserProfile.getUserData(),
    search: "",
    tituloDialogo: "Nuevo programa de aseo",
    dialog: false,
    crateEditVer: false,
    dialogDelete: false,
    lugares: ["Cliente", "Instalación"],
    tiposPlanificacion: ["Programado", "No programado"],
    tiposServicios: [
      "Aseo tradicional",
      "Aseo terminal",
      "Aseo recurrente",
      "Programa de aseo",
      "Vidrios a nivel",
      "Vidrios en altura",
      "Limpieza en altura",
    ],
    gruposTareas: ["Fin de semana", "Cada 15 días", "Cada 30 días"],
    parametrosLugar: ["Dirección", "Recinto", "Piso", "Zona/Área"],
    tiposTicket: [
      "Capacitación",
      "Tarea de RRHH",
      "Tarea administrativa",
      "Aviso cambio RRHH",
      "Mantenimiento de equipos",
    ],
    estadoTicket: ["No leído", "Abierto", "Resuelto", "Cerrado"],
    headers: [
      {
        text: "Título",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      { text: "Área de apoyo", value: "tipo" },
      // { text: "Descripción", value: "descripcion" },
      { text: "Lugar", value: "lugar" },
      { text: "Tipo de programa", value: "tipoPrograma" },
      { text: "Planificación", value: "tipoPlanificacion" },
      // { text: "Tipo de Creador", value: "tipoCreador" },
      { text: "Servicio", value: "tipoServicio" },
      // { text: "Grupo de tareas", value: "grupoTarea" },
      // { text: "Asignación", value: "asignado_a" },
      // { text: "Estado", value: "estado" },
      // { text: "Creado por", value: "creado_por" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    tickets: [],
    editedIndex: -1,
    editedItem: {
      titulo: "",
      tipo: "",
      lugar: "",
      parametrosLugar: "",
      tipoPrograma: "",
      tipoPlanificacion: "",
      // tipoCreador: "",
      tipoServicio: "",
      // grupoTarea: "",
      // descripcion: "",
      // asignado_a: "",
      // estado: "",
      // creado_por: "",
    },
  }),
  mounted() {
    // this.editedItem.creado_por = this.user.user;
  },
  computed: {
    formTitle() {
      // this.editedItem.creado_por = this.user.user;

      return this.editedIndex === -1
        ? "Nuevo programa de aseo"
        : "Editar programa de aseo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    recibeTickect(item) {
      console.log("estoy aqui", item);
      this.crateEditVer = false;

      if (item !== undefined) this.tickets.push(item);
    },
    mostrarDialogo() {
      this.crateEditVer = true;
      this.disabledTicket = false;
      this.dataTicket = {};
    },

    initialize() {
      this.tickets = [
        {
          titulo: "Título del ticket",
          tipo: "Capacitación",
          lugar: "Instalación",
          parametrosLugar: ["Piso", "Zona/Área"],
          tipoPrograma: "Fin de semana",
          tipoPlanificacion: "Programado",
          tipoCreador: "Cliente administrador",
          tipoServicio: "Aseo tradicional",
          grupoTarea: "Fin de semana",
          // descripcion: "",
          asignado_a: "Persona",
          // estado: "",
          // creado_por: this.user.user,
        },
      ];
    },
    getColor(estado) {
      if (estado == "No leído") return "default";
      else if (estado == "Resuelto") return "green";
      else if (estado == "Abierto") return "orange";
      else if (estado == "Cerrado") return "red";
    },

    editItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.editedItem = item;
      this.dialog = true;
      console.log(item);
      this.dataTicket = item;
      (this.tituloDialogo = "Editar programa de aseo"),
        (this.crateEditVer = true);
      this.disabledTicket = false;
    },

    deleteItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.dialogDelete = true;
    },

    viewItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.editedItem = item;
      (this.tituloDialogo = "Ver ticket"), (this.crateEditVer = true);
      this.dataTicket = item;
      this.disabledTicket = true;
      console.log("se vio");
    },

    deleteItemConfirm() {
      this.tickets.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tickets[this.editedIndex], this.editedItem);
      } else {
        this.tickets.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss">
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-card__title {
  word-break: break-word;
}
.v-text-field__details {
  display: none;
}
</style>
