<template>
  <div>
    <v-card class="no-shadow">
      <v-toolbar color="primary" class="mb-4 v-toolbar-header">
        <v-toolbar-title aling="center"
          ><v-icon dark>mdi-card-plus</v-icon>
          <div class="d-inline-block dark">
            Gestion de Almacenamiento
          </div></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <template>
          <v-menu top :close-on-click="closeOnClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="btnNuevo">
                <v-icon class="iconsAdd">mdi-plus</v-icon> Nuevo
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in listaOpcions" :key="index">
                <v-list-item-title @click="crearNuevo(item)">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
        class="mb-4"
      >
      </v-text-field>

      <v-breadcrumbs divider="/" class="pt-1 cursor">
        <v-breadcrumbs-item
          @click.native="goDirectory(item.id)"
          v-for="item in listaPosicionDirectorios"
          :key="item.id"
        >
          <template v-if="item.text !== 'Mi Unidad'">
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          {{ item.text }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>    

      <v-data-table
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-icon
            v-if="item.detail == 'Directorio'"
            @click="changePartent(item)"
            >{{ item.icon }}</v-icon
          >
          <v-icon v-else>{{ item.icon }}</v-icon>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span
            v-if="item.detail == 'Directorio'"
            @click="changePartent(item)"
            >{{ item.name }}</span
          >
          <span @click="donwloadFile(item)" v-else>{{ item.name }}</span>
        </template>

        <template v-slot:[`item.estado`]="{ item }">
          <v-chip :color="getColor(item.estado)" dark>
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            v-if="item.detail != 'Directorio'"
            @click="donwloadFile(item)"
          >
            mdi-arrow-collapse-down
          </v-icon>

          <v-icon small class="ml-2 mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>

          <v-icon small color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          No hay items
        </template>
      </v-data-table>

      
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Confirme desea eliminar permanentemente 
          {{ tipoEliminar }}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Volver</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="col-12">
      <v-dialog v-model="addArchivo" max-width="500px">
        <div class="container">
          <h3>{{ selecciono }}</h3>
          <div class="row">
            <div class="col-12 pb-0">
              <v-text-field
                v-if="
                  selecciono == 'Crear Carpeta' ||
                    selecciono == 'Editar Carpeta'
                "
                label="Nombre"
                outlined
                v-model="nombreCarpetaArchivo"
                required
              ></v-text-field>
              <!-- <input type="file" class="custom-file-input"  v-on:change="obtenerFile"  /> -->
              <v-file-input
                v-if="
                  selecciono == 'Crear Archivo' ||
                    selecciono == 'Editar Archivo'
                "
                v-on:change="obtenerFile"
                label="Ingrese nuevo archivo"
                outlined
                v-model="chosenFile"
              >
              </v-file-input>
            </div>
            <div class="col-12 pb-0 text-right" @click="crear">
              <v-btn color="primary"> {{ selecciono }} </v-btn>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import api from "../../conf/ruta-base";
import store from "../../store";
import UserProfile from "../../data";
import createTickets from "../tickets/create-tickets.vue";
const FileDownload = require("js-file-download");
export default {
  components: {
    createTickets,
  },
  data: () => ({
    listaOpcions: [{ title: "Carpeta" }, { title: "Archivo" }],
    closeOnClick: true,
    parent: "/", // /Administracion/Pagos/Enero
    chosenFile: null,
    fileTypes: {
      "application/pdf": "mdi-file-pdf",
      "image/png": "mdi-file-image",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "mdi-file-excel",
    },
    user: UserProfile.getUserData(),
    dataTicket: null,
    disabledTicket: false,
    addArchivo: false,
    isEdith: {},
    search: "",
    tipoEliminar: "",
    nombreCarpetaArchivo: "",
    eliminarSeleccion: "",
    selecciono: "",
    tituloDialogo: "Nuevo ticket",
    dialog: false,
    crateEditVer: false,
    dialogDelete: false,
    headers: [
      { text: "", value: "icon" },
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Detalles", value: "detail" },
      { text: "Elementos", value: "elements" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    datos: [],
    editedIndex: -1,
    editedItem: {
      titulo: "",
      tipo: "",
      lugar: "",
      parametrosLugar: "",
      tipoPlanificacion: "",
      tipoCreador: "",
      tipoServicio: "",
      grupoTarea: "",
      // descripcion: "",
      asignado_a: "",
      // estado: "",
      // creado_por: "",
    },
    listaPosicionDirectorios: [
      {
        text: "Mi Unidad",
        disabled: false,
        id: null,
      },
    ],
  }),
  mounted() {
    this.location();
  },
  computed: {
    formTitle() {
      // this.editedItem.creado_por = this.user.user;

      return this.editedIndex === -1 ? "Nuevo ticket" : "Editar ticket";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    location() {
      this.datos = [];
      //Consultamos las carpetas que esten a la altura del directorio
      let filter = {
        where: {
          parent: this.parent,
        },
      };
      axios
        .get(`${api.api}directorys?filter=${JSON.stringify(filter)}`)
        .then((res) => {
          res.data.forEach((folder) => {
            this.datos.push({
              icon: "mdi-folder",
              name: folder.name,
              detail: "Directorio",
              elements: folder.objects,
              parent: folder.parent,
              id: folder.id,
            });
          });
        });
      //Fin directorios
      //Consultamos los archivos
      filter = {
        where: {
          directory: this.parent,
        },
      };
      axios
        .get(`${api.api}files?filter=${JSON.stringify(filter)}`)
        .then((res) => {
          res.data.forEach((files) => {
            this.datos.push({
              icon: this.fileTypes[files.type] || "mdi-file",
              name: files.localName,
              detail: "Archivo",
              elements: 1,
              id: files.id,
            });
          });
        });
      //Fin archivos
    },
    goDirectory(imtes) {
      if (imtes === null && this.parent != "/") {
        this.parent = "/";
        this.listaPosicionDirectorios = [
          {
            text: "Mi Unidad",
            disabled: false,
            id: null,
          },
        ];
        this.location();
        return null;
      }
      let bandera = true;
      let parent = "";
      const newLista = [];
      this.listaPosicionDirectorios.forEach((elemento) => {
        if (bandera) {
          newLista.push(elemento);
          parent = elemento.id != null ? parent + elemento.text : "/";
        }
        if (elemento.id == imtes) bandera = false;
      });
      this.listaPosicionDirectorios = newLista;
      this.parent = parent;
      this.location();
    },
    recibeTickect(item) {
      console.log("estoy aqui", item);
      this.crateEditVer = false;

      if (item !== undefined) this.tickets.push(item);
    },
    changePartent(item) {
      if (!item.parent) return null;
      this.listaPosicionDirectorios.push({
        text: item.name,
        disabled: false,
        id: item.id,
      });
      this.parent = item.parent + item.name;
      this.location();
    },
    mostrarDialogo() {
      this.addArchivo = true;
    },

    initialize() {
      this.tickets = [
        {
          titulo: "Título del ticket",
          tipo: "Capacitación",
          lugar: "Instalación",
          parametrosLugar: ["Piso", "Zona/Área"],
          tipoPlanificacion: "Programado",
          tipoCreador: "Cliente administrador",
          tipoServicio: "Aseo tradicional",
          grupoTarea: "Fin de semana",
          asignado_a: "Persona",
        },
      ];
    },
    getColor(estado) {
      if (estado == "No leído") return "default";
      else if (estado == "Resuelto") return "green";
      else if (estado == "Abierto") return "orange";
      else if (estado == "Cerrado") return "red";
    },

    editItem(item) {
      console.log("soy edith", item);
      if (item.detail == "Directorio") {
        this.selecciono = "Editar Carpeta";
        this.nombreCarpetaArchivo = item.name;
        this.isEdith = item;
        this.addArchivo = true;
      } else {
        this.selecciono = "Editar Archivo";
        this.nombreCarpetaArchivo = item.name;
        this.isEdith = item;
        this.addArchivo = true;
      }

      // this.editedIndex = this.tickets.indexOf(item);
      // this.editedItem = item;
      // this.dialog = true;
      // console.log(item);
      // this.dataTicket = item;
      // (this.tituloDialogo = "Editar ticket"), (this.crateEditVer = true);
      // this.disabledTicket = false;
    },

    deleteItem(item) {
      this.eliminarSeleccion = item;
      this.tipoEliminar = item.detail;
      this.dialogDelete = true;
    },

    viewItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.editedItem = item;
      (this.tituloDialogo = "Ver ticket"), (this.crateEditVer = true);
      this.dataTicket = item;
      this.disabledTicket = true;
      console.log("se vio");
    },

    async deleteItemConfirm() {
      const index = this.datos.indexOf(this.eliminarSeleccion);
      this.dialogDelete = false;
      let type =
        this.eliminarSeleccion.detail == "Archivo" ? "files" : "directorys";
      await axios
        .delete(`${api.api}${type}/${this.eliminarSeleccion.id}`)
        .then((res) => {
          if (res.status === 204) {
            this.datos.splice(index, 1);
            console.log(index, this.datos);
            this.eliminarSeleccion = null;
          }
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    donwloadFile(e) {
      console.log(e.id);
      if (!e.id) return null;
      axios
        .get(`${api.api}documents/files/${e.id}`, {
          responseType: "blob",
          "Content-Disposition": "attachment",
        })
        .then((response) => {
          FileDownload(response.data, e.name);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tickets[this.editedIndex], this.editedItem);
      } else {
        this.tickets.push(this.editedItem);
      }
      this.close();
    },
    crearNuevo(item) {
      this.selecciono = "Crear" + " " + item.title;
      this.addArchivo = true;
    },
    obtenerFile(e) {
      let file = new FormData();
      file.append("file", this.chosenFile, this.chosenFile.name);
      console.log(this.chosenFile, this.chosenFile.name);
      console.log("soy file", file);
    },

    async crear() {
      this.addArchivo = false;
      let directorys = "";
      let obj;
      if (this.selecciono == "Crear Archivo") {
        let file = new FormData();
        file.append("file", this.chosenFile, this.chosenFile.name);
        file.append("directory", this.parent);
        directorys = "documents/files";
        obj = file;
        const createdDirectory = await axios.post(api.api + directorys, obj);
        console.log("createdDirectory", createdDirectory);
      } else if (this.selecciono == "Editar Archivo") {
        directorys = "documents/files/" + this.isEdith.id;
        obj = {
          id: this.isEdith.id,
          name: this.nombreCarpetaArchivo,
          parent: this.editedItem.parent,
          objects: 0,
        };

        const createdDirectory = await axios.put(api.api + directorys, obj);
        console.log("createdDirectory", createdDirectory);
      } else if (this.selecciono == "Editar Carpeta") {
        directorys = "directorys/" + this.isEdith.id;
        obj = {
          name: this.nombreCarpetaArchivo,
          parent: this.parent,
          objects: 0,
        };
        const createdDirectory = await axios.put(api.api + directorys, obj);
        console.log("createdDirectory", createdDirectory);
      } else if (this.selecciono == "Crear Carpeta") {
        directorys = "directorys";
        obj = {
          name: this.nombreCarpetaArchivo,
          parent: this.parent,
          objects: 0,
        };
        const createdDirectory = await axios.post(api.api + directorys, obj);
        console.log("createdDirectory", createdDirectory);
      }

      this.nombreCarpetaArchivo = "";
      this.chosenFile = null;
      this.location();
    },

    chooseFiles() {},
  },

  toast(color, text) {
    var toast = { color, text };
    store.commit("TOAST", toast);
  },
};
</script>

<style lang="scss">
.v-card__title {
  word-break: break-word;
}
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-text-field__details {
  display: none;
}
.btnNuevo {
  border-radius: 45px;
  border: 1px solid #55525229;
  padding: 10px;
  color: #4f4c4cdb;
  box-shadow: 1px 2px 2px 0px #52545566;
  width: 143px;
}
.v-dialog {
  background-color: #ffffff;
}
.iconsAdd {
  color: #019fe0 !important;
  font-size: 35px !important;
  width: 45px !important;
}
.dark {
  color: #ffffff;
}
input[type="”file”"]#nuestroinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
